<template>
  <div>
    <v-row>
      <v-col v-if="dataNotBlacklisted(fields.SERVICE_FEE_MATRIX_ID)" cols="12">
        <legend class="body-1">
          {{ $t('creditors.serviceFees') }}
        </legend>
        <div class="mt-2">
          <service-fee-matrix-radio-group
            id="creditor-registration__service-fee"
            v-model="conditionsData[fields.SERVICE_FEE_MATRIX_ID]"
            :creditor-product-type="selectedProduct.type"
            :disabled="isDisabled"
            :rules="getRules(fields.SERVICE_FEE_MATRIX_ID, 'required')"
            name="creditors.serviceFees"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import OnboardingProductMixin from '@/mixins/OnboardingProductMixin';
import ServiceFeeMatrixRadioGroup from '@/modules/Admin/components/Creditors/ServiceFeeMatrixRadioGroup';

export default {
  name: 'ConditionsForm',

  components: { ServiceFeeMatrixRadioGroup },

  mixins: [OnboardingProductMixin],

  props: {
    formData: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    conditionsData: {}
  }),

  computed: {
    isDisabled() {
      return !!this.$route.query[this.queryParam.EDIT_CREDITOR];
    }
  },

  watch: {
    formData: {
      handler() {
        if (
          JSON.stringify(this.formData[this.fields.CONDITIONS]) ===
          JSON.stringify(this.conditionsData)
        ) {
          return;
        }

        this.conditionsData = { ...this.formData[this.fields.CONDITIONS] };
      },
      immediate: true,
      deep: true
    },

    conditionsData: {
      handler(value) {
        this.$emit('update:form-data', {
          ...this.formData,
          [this.fields.CONDITIONS]: value
        });
      },
      deep: true
    }
  }
};
</script>
