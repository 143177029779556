<template>
  <div>
    <v-row>
      <v-col v-if="dataNotBlacklisted(fields.ENTITLED_TO_DEDUCT_VAT)" cols="12">
        <app-radio-group
          id="creditor-registration__deduct-vat"
          v-model="legalData[fields.ENTITLED_TO_DEDUCT_VAT]"
          :items="taxDeductionItems"
          label="onboarding.entitledToDeductVat"
          :rules="getRules(fields.ENTITLED_TO_DEDUCT_VAT, 'required')"
        />
      </v-col>
      <v-col v-if="dataNotBlacklisted(fields.VOB) && isOnboarding" cols="12">
        <app-radio-group
          id="creditor-registration__vob"
          v-model="legalData[fields.VOB]"
          :items="vobItems"
          name="onboarding.vob"
          :rules="getRules(fields.VOB, 'required')"
        >
          <template #label>
            {{
              $t('onboarding.vobLabel') +
              (getRules(fields.VOB, 'required').includes('required') ? '*' : '')
            }}
            <app-tooltip inline>
              <template #text>
                <span v-html="$t('onboarding.vobInfoHtml')"></span>
              </template>
            </app-tooltip>
          </template>
        </app-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="dataNotBlacklisted(fields.TAX_NUMBER)" cols="12">
        <app-text-field
          id="creditor-registration__tax-number"
          v-model="legalData[fields.TAX_NUMBER]"
          label="taxNumber"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="dataNotBlacklisted(fields.REGISTER_NUMBER)" cols="12">
        <app-text-field
          id="creditor-registration__register-number"
          v-model="legalData[fields.REGISTER_NUMBER]"
          label="registerNumber"
          :rules="getRules(fields.REGISTER_NUMBER, !isOnboarding && useFactoring ? 'required' : '')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="dataNotBlacklisted(fields.PLACE_OF_AUTHORITY)" cols="12">
        <app-text-field
          id="creditor-registration__place-of-authority"
          v-model="legalData[fields.PLACE_OF_AUTHORITY]"
          label="placeOfAuthority"
          :rules="
            getRules(fields.PLACE_OF_AUTHORITY, !isOnboarding && useFactoring ? 'required' : '')
          "
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="dataNotBlacklisted(fields.PROPRIETOR)" cols="12">
        <app-text-field
          id="creditor-registration__proprietor"
          v-model="legalData[fields.PROPRIETOR]"
          label="proprietor"
          :rules="getRules(fields.PROPRIETOR, useFactoring ? 'required' : '')"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import OnboardingProductMixin from '@/mixins/OnboardingProductMixin';

export default {
  name: 'LegalFormalitiesForm',

  mixins: [OnboardingProductMixin],

  props: {
    formData: {
      type: Object,
      required: true
    },
    useFactoring: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    legalData: {}
  }),

  computed: {
    taxDeductionItems() {
      return [
        {
          label: this.$t('yes'),
          value: 1,
          id: 'creditor-registration__deduct-vat--yes'
        },
        {
          label: this.$t('no'),
          value: 0,
          id: 'creditor-registration__deduct-vat--no'
        }
      ];
    },
    vobItems() {
      return [
        {
          label: this.$t('yes'),
          value: 1,
          id: 'creditor-registration__vob--yes'
        },
        {
          label: this.$t('no'),
          value: 0,
          id: 'creditor-registration__vob--no'
        }
      ];
    }
  },

  watch: {
    formData: {
      handler() {
        if (JSON.stringify(this.formData[this.fields.LEGAL]) === JSON.stringify(this.legalData)) {
          return;
        }

        this.legalData = { ...this.formData[this.fields.LEGAL] };
      },
      immediate: true,
      deep: true
    },
    legalData: {
      handler(value) {
        this.$emit('update:form-data', {
          ...this.formData,
          [this.fields.LEGAL]: value
        });
      },
      deep: true
    }
  }
};
</script>
