<template>
  <div>
    <v-row>
      <v-col v-if="dataNotBlacklisted(fields.NAME)" cols="12">
        <app-text-field
          id="creditor-registration__company"
          v-model="companyData[fields.NAME]"
          label="company"
          :rules="getRules(fields.NAME, 'required')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="dataNotBlacklisted(fields.SALUTATION)" cols="12" md="2">
        <app-salutation-select
          id="creditor-registration__salutation"
          v-model="companyData[fields.SALUTATION]"
        />
      </v-col>
      <v-col v-if="dataNotBlacklisted(fields.FIRST_NAME)" cols="12" md="5">
        <app-text-field
          id="creditor-registration__first-name"
          v-model="companyData[fields.FIRST_NAME]"
          label="firstName"
        />
      </v-col>
      <v-col v-if="dataNotBlacklisted(fields.LAST_NAME)" cols="12" md="5">
        <app-text-field
          id="creditor-registration__last-name"
          v-model="companyData[fields.LAST_NAME]"
          label="lastName"
          :rules="getRules(fields.LAST_NAME, 'required')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-if="dataNotBlacklisted(fields.TRADE_NAME)"
        cols="12"
        :md="alignTradenameAndTurnover || alignTradenameAndFoundingDate ? 8 : 12"
      >
        <app-text-field
          id="creditor-registration__trade-name"
          v-model="companyData[fields.TRADE_NAME]"
          label="tradeName"
        />
      </v-col>
      <v-col v-if="showFoundingDate" cols="12" md="4">
        <app-date-picker
          id="creditor-registration__founding-date"
          v-model="companyData[fields.FOUNDING_DATE]"
          :custom-error-messages="{ min_past_months: $t('onboarding.foundingDateNotValid') }"
          :max="maxFoundingDate"
          :rules="getRules(fields.FOUNDING_DATE, 'required|min_past_months:6')"
          append-icon
          label="onboarding.foundingDate"
          placeholder="dateHint"
        />
      </v-col>
      <v-col
        v-if="dataNotBlacklisted(fields.TURNOVER)"
        cols="12"
        :md="alignTradenameAndTurnover ? 4 : 6"
      >
        <app-number-field
          id="creditor-registration__turnover"
          v-model="companyData[fields.TURNOVER]"
          :rules="turnoverRules"
          append-icon="$iconEuro"
          label="annualTurnover"
        />
      </v-col>
      <v-col v-if="showOnlineTurnoverRate" cols="12" md="6">
        <app-text-field
          id="creditor-registration__online-turnover-rate"
          v-model="companyData[fields.ONLINE_TURNOVER_RATE]"
          :required="useFactoring"
          :rules="getRules(fields.ONLINE_TURNOVER_RATE, 'required|percent|max_value:40')"
          :custom-error-messages="{
            max_value: $t('onboarding.maxOnlineTurnoverRateInvalid', { max: 40 })
          }"
          label="onboarding.annualOnlineTurnoverRate"
          max="100"
          min="0"
          step="0.1"
          type="number"
        />
      </v-col>
      <v-col v-if="showCalculatedCreditorLimit && isOnboarding" cols="12" md="6">
        <app-number-field
          id="creditor-registration__factorable-turnover"
          ref="factorableTurnover"
          :value="factorableTurnover"
          :rules="getRules(fields.FACTORABLE_TURNOVER, 'factorable_turnover')"
          append-icon="$iconEuro"
          disabled
          hide-supplement
          label="onboarding.factorableAnnualTurnover"
        />
      </v-col>
      <v-col v-if="showCalculatedCreditorLimit" cols="12" md="6">
        <app-number-field
          id="creditor-registration__calculated-creditor-limit"
          :rules="getRules(fields.CREDITOR_LIMIT, '')"
          :value="creditorLimit"
          append-icon="$iconEuro"
          disabled
          hide-supplement
          label="onboarding.determinedCreditorLimit"
        />
      </v-col>
      <v-col v-if="showWritableCreditorLimit" cols="12" md="6">
        <app-number-field
          id="creditor-registration__writable-creditor-limit"
          v-model="companyData[fields.CREDITOR_LIMIT]"
          :rules="getRules(fields.CREDITOR_LIMIT, 'required')"
          append-icon="$iconEuro"
          label="onboarding.annualMaximumCompensation"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="dataNotBlacklisted(fields.STREET_AND_HOUSE)" cols="12">
        <app-text-field
          id="creditor-registration__street-and-house"
          v-model="companyData[fields.STREET_AND_HOUSE]"
          label="streetAndHouse"
          :rules="getRules(fields.STREET_AND_HOUSE, 'required')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="dataNotBlacklisted(fields.ZIP)" cols="12" md="3">
        <app-text-field
          id="creditor-registration__zip"
          v-model="companyData[fields.ZIP]"
          label="zip"
          :rules="getRules(fields.ZIP, 'required|numeric|length: 5')"
        />
      </v-col>
      <v-col v-if="dataNotBlacklisted(fields.CITY)" cols="12" md="9">
        <app-text-field
          id="creditor-registration__city"
          v-model="companyData[fields.CITY]"
          label="city"
          :rules="getRules(fields.CITY, 'required')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="dataNotBlacklisted(fields.PHONE)" cols="12" md="6">
        <app-text-field
          id="creditor-registration__phone"
          v-model="companyData[fields.PHONE]"
          label="phone"
          :rules="getRules(fields.PHONE, 'required|phone')"
        />
      </v-col>
      <v-col v-if="dataNotBlacklisted(fields.FAX)" cols="12" md="6">
        <app-text-field
          id="creditor-registration__fax"
          v-model="companyData[fields.FAX]"
          label="fax"
          :rules="getRules(fields.FAX, 'phone')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="dataNotBlacklisted(fields.MOBILE)" cols="12" md="6">
        <app-text-field
          id="creditor-registration__mobile"
          v-model="companyData[fields.MOBILE]"
          label="mobile"
          :rules="getRules(fields.MOBILE, 'phone')"
        />
      </v-col>
      <v-col v-if="dataNotBlacklisted(fields.EMAIL)" cols="12" md="6">
        <app-text-field
          id="creditor-registration__email"
          v-model="companyData[fields.EMAIL]"
          label="email"
          :rules="getRules(fields.EMAIL, 'required|email')"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AppSalutationSelect from '@/shared/components/AppSalutationSelect';
import OnboardingProductMixin from '@/mixins/OnboardingProductMixin';
import calculateAnnualServiceFee from '@/onboarding/helper/calculateAnnualServiceFee';
import calculateCreditorLimitFromTurnover from '@/onboarding/helper/calculateCreditorLimitFromTurnover';
import calculateFactorableAnnualTurnover from '@/onboarding/helper/calculateFactorableAnnualTurnover';
import { formatIsoDateOfMonthsFromToday } from '@/helper/filter/formatDate';

export default {
  name: 'CompanyForm',

  mixins: [OnboardingProductMixin],

  components: {
    AppSalutationSelect
  },

  props: {
    formData: {
      type: Object,
      required: true
    },
    useFactoring: {
      type: Boolean,
      required: true
    }
  },

  data: () => ({
    companyData: {}
  }),

  computed: {
    factorableTurnover() {
      return calculateFactorableAnnualTurnover(
        this.companyData[this.fields.TURNOVER],
        this.companyData[this.fields.ONLINE_TURNOVER_RATE]
      );
    },
    creditorLimit() {
      return calculateCreditorLimitFromTurnover(
        this.isOnboarding ? this.factorableTurnover : this.companyData[this.fields.TURNOVER],
        this.selectedProduct.onboarding.maxCreditorLimitInCent
      );
    },
    annualServiceFee() {
      return calculateAnnualServiceFee(
        this.isOnboarding ? this.factorableTurnover : this.companyData[this.fields.TURNOVER],
        this.formData[this.fields.CONTRACT].serviceFee
      );
    },
    turnoverRules() {
      const defaultRules = `${this.useFactoring ? 'required|' : ''}min_currency:0`;

      return this.isOnboarding ? this.getRules(this.fields.TURNOVER, defaultRules) : defaultRules;
    },
    showOnlineTurnoverRate() {
      return this.dataNotBlacklisted(this.fields.ONLINE_TURNOVER_RATE) && this.isOnboarding;
    },
    showCalculatedCreditorLimit() {
      return (
        this.dataNotBlacklisted(this.fields.CREDITOR_LIMIT) &&
        this.selectedProduct.onboarding.useCalculatedCreditorLimit
      );
    },
    showWritableCreditorLimit() {
      return (
        this.dataNotBlacklisted(this.fields.CREDITOR_LIMIT) &&
        !this.selectedProduct.onboarding.useCalculatedCreditorLimit &&
        !this.isOnboarding
      );
    },
    showFoundingDate() {
      return this.dataNotBlacklisted(this.fields.FOUNDING_DATE) && this.isOnboarding;
    },
    maxFoundingDate() {
      return formatIsoDateOfMonthsFromToday(-6);
    },
    alignTradenameAndFoundingDate() {
      return this.showFoundingDate;
    },
    alignTradenameAndTurnover() {
      return (
        !this.showWritableCreditorLimit &&
        !this.showCalculatedCreditorLimit &&
        !this.alignTradenameAndFoundingDate
      );
    }
  },

  watch: {
    formData: {
      handler() {
        if (
          JSON.stringify(this.formData[this.fields.COMPANY]) === JSON.stringify(this.companyData)
        ) {
          return;
        }

        this.companyData = { ...this.formData[this.fields.COMPANY] };
      },
      immediate: true,
      deep: true
    },
    companyData: {
      handler(value) {
        this.$emit('update:form-data', {
          ...this.formData,
          [this.fields.COMPANY]: value
        });
      },
      deep: true
    },

    async factorableTurnover() {
      await this.$nextTick(); // wait for internal field value to update
      await this.$refs.factorableTurnover.validateField();
    }
  }
};
</script>
