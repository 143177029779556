<template>
  <div>
    <v-row>
      <v-col
        v-if="
          dataNotBlacklisted(fields.LEGAL_FORM_KEY) && dataNotBlacklisted(fields.LEGAL_FORM_VALUE)
        "
        cols="12"
      >
        <legal-form-select
          v-model="contractData[fields.LEGAL_FORM][fields.LEGAL_FORM_KEY]"
          :legal-form.sync="contractData[fields.LEGAL_FORM][fields.LEGAL_FORM_VALUE]"
          :label="$t('creditors.legalForm')"
          :rules="getRules(fields.LEGAL_FORM_VALUE, 'required')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="dataNotBlacklisted(fields.BUERGEL_SCORE)" cols="12" md="6">
        <app-text-field v-model="buergelScore" label="creditors.buergelScore" />
      </v-col>
      <v-col v-if="dataNotBlacklisted(fields.CSP_IDENTIFIER)" cols="12" md="6">
        <app-text-field
          v-model="contractData[fields.CSP_IDENTIFIER]"
          label="creditors.cspIdentifier"
          :rules="getRules(fields.CSP_IDENTIFIER, 'required')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="dataNotBlacklisted(fields.DDM_IDENTIFIER)" cols="12" md="6">
        <app-text-field
          v-model="contractData[fields.DDM_IDENTIFIER]"
          label="creditors.ddmIdentifier"
          :rules="getRules(fields.DDM_IDENTIFIER, 'required')"
        />
      </v-col>
      <v-col v-if="dataNotBlacklisted(fields.WZ_CODE)" cols="12" md="6">
        <app-text-field v-model="contractData[fields.WZ_CODE]" label="creditors.wzCode" />
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="dataNotBlacklisted(fields.CONTRACT_DATE)" cols="12" md="6">
        <app-date-picker
          v-model="contractData[fields.CONTRACT_DATE]"
          :disabled="!isNewCreditor"
          :max="maxContractStartDate"
          :rules="getRules(fields.DATE, 'required')"
          append-icon
          label="creditors.contractDate"
          name="date"
          placeholder="dateHint"
        />
      </v-col>
      <v-col v-if="dataNotBlacklisted(fields.SET_UP_FEE)" cols="12" md="6">
        <setup-fee-select
          v-model="contractData[fields.SET_UP_FEE]"
          :rules="getRules(fields.SET_UP_FEE, 'required')"
        />
      </v-col>
    </v-row>

    <template v-if="showAnnualServiceFee && isNewCreditor">
      <v-row>
        <v-col cols="12" md="6">
          <app-number-field
            v-model="contractData[fields.ANNUAL_SERVICE_FEE]"
            :rules="getRules(fields.ANNUAL_SERVICE_FEE, 'required|min_equal_currency:0')"
            append-icon="$iconEuro"
            label="serviceFees.annualServiceFee"
            placeholder="0,00"
          />
        </v-col>
      </v-row>
    </template>
    <template v-else-if="showAnnualServiceFee && !isNewCreditor">
      <p class="mt-4">{{ $t('serviceFees.annualServiceFee') }}</p>
      <v-row>
        <template v-if="!isNewCreditor">
          <v-col cols="12" md="6">
            <app-number-field
              :rules="getRules(fields.ANNUAL_SERVICE_FEE, 'required')"
              :value="contractData.serviceFeePeriod.currentPeriod.serviceFee"
              append-icon="$iconEuro"
              disabled
              name="serviceFees.annualServiceFee"
              placeholder="0,00"
            />
            {{ $t('creditors.forCurrentServiceFeePeriod') }}
            {{ buildServiceFeePeriod(contractData.serviceFeePeriod.currentPeriod) }}
          </v-col>

          <v-col cols="12" md="6">
            <app-number-field
              v-model="contractData[fields.ANNUAL_SERVICE_FEE]"
              :rules="getRules(fields.ANNUAL_SERVICE_FEE, 'required')"
              append-icon="$iconEuro"
              name="serviceFees.annualServiceFee"
              placeholder="0,00"
            />
            {{ $t('creditors.forNextServiceFeePeriod') }}
            {{ buildServiceFeePeriod(contractData.serviceFeePeriod.nextPeriod) }}
          </v-col>
        </template>
      </v-row>
    </template>
  </div>
</template>

<script>
import LegalFormSelect from '@/modules/Admin/components/Creditors/LegalFormSelect';
import OnboardingProductMixin from '@/mixins/OnboardingProductMixin';
import SetupFeeSelect from '@/modules/Admin/components/Creditors/SetupFeeSelect';
import formatDate, { formatTimestampToDate } from '@/helper/filter/formatDate';

export default {
  name: 'ContractForm',

  mixins: [OnboardingProductMixin],

  components: { SetupFeeSelect, LegalFormSelect },

  props: {
    formData: {
      type: Object,
      required: true
    },
    useFactoring: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    contractData: {}
  }),

  computed: {
    buergelScore: {
      get() {
        return this.contractData[this.fields.BUERGEL_SCORE].replace('.', ',');
      },
      set(buergelScore) {
        this.contractData[this.fields.BUERGEL_SCORE] = (buergelScore ?? '').replace(',', '.');
      }
    },
    maxContractStartDate() {
      return formatTimestampToDate(new Date().setHours(0, 0, 0) / 1000);
    },
    isNewCreditor() {
      return this.$route.query[this.queryParam.CREATE_CREDITOR];
    },
    isCreditorWithServiceFeePeriod() {
      return !!this.contractData.serviceFeePeriod;
    },
    showAnnualServiceFee() {
      return (
        this.dataNotBlacklisted(this.fields.ANNUAL_SERVICE_FEE) &&
        this.useFactoring &&
        (this.isNewCreditor || this.isCreditorWithServiceFeePeriod)
      );
    }
  },

  watch: {
    formData: {
      handler() {
        if (
          JSON.stringify(this.formData[this.fields.CONTRACT]) === JSON.stringify(this.contractData)
        ) {
          return;
        }
        this.contractData = { ...this.formData[this.fields.CONTRACT] };
      },
      immediate: true,
      deep: true
    },
    contractData: {
      handler(value) {
        this.$emit('update:form-data', {
          ...this.formData,
          [this.fields.CONTRACT]: value
        });
      },
      deep: true
    }
  },

  methods: {
    buildServiceFeePeriod(periodData) {
      return `(${formatDate(periodData.start)} - ${formatDate(periodData.end)})`;
    }
  }
};
</script>
