<template>
  <ValidationObserver ref="form" v-slot="{ failed }">
    <v-card flat>
      <v-card-title class="body-1 justify-center">
        <v-row class="stepper">
          <v-col
            v-for="step in steps"
            :key="step.step"
            v-ripple="nonLinear"
            :class="{
              'd-flex align-center break-word': true,
              'stepper__step--selectable': nonLinear
            }"
            @click="nonLinear ? goToStep(step) : undefined"
          >
            <span
              v-if="currentStep - 1 < step.step"
              :class="{
                'stepper__step-index': true,
                'gradient-diagonal--primary': currentStep === step.step
              }"
            >
              {{ step.step }}
            </span>
            <img
              v-else
              :src="require('@/onboarding/assets/check.svg')"
              :alt="step.step"
              class="stepper__step-index"
            />

            <span class="ml-2 font-weight-light">{{ $t(step.title) }}</span>
          </v-col>
          <v-col cols="12" class="d-flex" style="position: relative">
            <v-progress-linear
              height="5"
              :value="currentStep * (100 / steps.length)"
              color="secondary"
            />
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <error-alert :value="failed" />
        <slot name="prepend-inner"></slot>

        <component
          :is="steps[currentStepIndex].component"
          class="mt-4"
          :form-data="formData"
          :use-factoring="useFactoring"
          :disabled="disabled"
          @update:form-data="$emit('update:form-data', $event)"
        />
        <div v-if="isOnboarding" class="body-2 mt-4">*{{ $t('onboarding.requiredFields') }}</div>
      </v-card-text>

      <v-card-actions>
        <v-row justify="center" align="stretch">
          <v-col cols="6" md="2">
            <app-btn
              id="creditor-registration__btn-cancel"
              block
              outlined
              :title="currentStep > 1 ? 'navigation.goBack' : 'navigation.cancel'"
              :disabled="loading"
              @click="onCancel"
            />
          </v-col>
          <v-col cols="6" md="2">
            <app-btn
              v-if="currentStep < steps.length"
              id="creditor-registration__btn-submit"
              type="button"
              block
              title="navigation.continue"
              @click="onNext"
            />

            <app-btn
              v-else
              id="creditor-registration__btn-submit"
              type="button"
              block
              title="navigation.send"
              :disabled="disabled"
              :loading="loading"
              @click="onSubmit"
            />
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </ValidationObserver>
</template>

<script>
import ErrorAlert from '@/shared/CreateCreditor/ErrorAlert';
import SuccessCard from '@/onboarding/components/SuccessCard';
import CompanyForm from '@/shared/CreateCreditor/CompanyForm';
import LegalFormalitiesForm from '@/shared/CreateCreditor/LegalFormalitiesForm';
import SepaForm from '@/shared/CreateCreditor/SepaForm';
import IntermediaryForm from '@/shared/CreateCreditor/IntermediaryForm';
import ContractForm from '@/modules/Admin/components/Creditors/ContractForm';
import ConditionsForm from '@/modules/Admin/components/Creditors/ConditionsForm';
import { FACTORING_PRODUCTS } from '@/statics/productType';

export default {
  name: 'RegisterCreditorStepper',

  components: {
    ErrorAlert,
    SuccessCard,
    CompanyForm,
    LegalFormalitiesForm,
    SepaForm,
    IntermediaryForm,
    ContractForm,
    ConditionsForm
  },

  props: {
    formData: {
      type: Object,
      required: true
    },
    steps: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    selectedProductType: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    nonLinear: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    currentStep: {
      get() {
        return parseInt(this.getQueryParam(this.queryParam.STEP) ?? '1', 10);
      },
      set(value) {
        this.setQueryParam(this.queryParam.STEP, value);
      }
    },
    currentStepIndex() {
      return this.currentStep - 1;
    },
    useFactoring() {
      return this.selectedProductType
        ? FACTORING_PRODUCTS.includes(this.selectedProductType)
        : this.isFactoring;
    }
  },

  methods: {
    async onNext() {
      if (!(await this.$refs.form.validate())) {
        return this.scrollToFaultyInput();
      }

      if (this.currentStep < this.steps.length) {
        this.currentStep++;
      }
    },

    async onSubmit() {
      if (!(await this.$refs.form.validate())) {
        return this.scrollToFaultyInput();
      }

      this.$emit('submit');
    },

    onCancel() {
      if (this.currentStep > 1) {
        this.currentStep--;
        return;
      }

      this.$refs.form?.reset();
      this.$emit('cancel');
    },

    async goToStep(step) {
      if (!(await this.$refs.form.validate())) {
        return this.scrollToFaultyInput();
      }

      this.currentStep = step.step;
    },

    scrollToFaultyInput() {
      this.$vuetify.goTo(document.querySelector('.input--has-error'), {
        duration: 500,
        offset: 0,
        easing: 'easeInOutCubic',
        container:
          this.$route.name === this.routeName.CREDITORS ? '.scroll-overflow' : '.app-container'
      });
    }
  }
};
</script>

<style scoped>
.stepper__step--selectable {
  cursor: pointer;
  position: relative;
}

.stepper__step--selectable::before {
  background-color: currentColor;
  opacity: 0;
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.stepper__step--selectable:hover::before {
  opacity: 0.04;
}

.stepper__step-index {
  height: 40px;
  min-width: 40px;
  border-radius: 50%;
  font-size: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--c-background-text);
  background: #d6d6d6;
}
>>> .v-progress-linear__background {
  opacity: 0.05 !important;
}
>>> .v-progress-linear__determinate {
  background: linear-gradient(
    201.51deg,
    var(--c-background-gradient-light) 0%,
    var(--c-background-gradient-dark) 100%
  );
}
</style>
