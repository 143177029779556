<template>
  <app-radio-group
    v-model="selected"
    :items="serviceFeeMatrixItems"
    :name="name"
    :rules="rules"
    class="service-fee-matrix__radio-group"
    v-bind="$attrs"
  >
    <app-spinner v-if="loadingItems" class="service-fee-matrix__spinner" />
    <template #radio>
      <v-radio
        v-for="item in serviceFeeMatrixItems"
        :key="item.id"
        :value="item.id"
        class="service-fee-matrix__radio"
      >
        <template #label>
          <div>
            <span class="service-fee-matrix__name">{{ item.name }}</span>
            <service-fee-condition-details :service-fee-matrix="item" />
          </div>
        </template>
      </v-radio>
    </template>
  </app-radio-group>
</template>

<script>
import OnboardingProductMixin from '@/mixins/OnboardingProductMixin';
import ServiceFeeConditionDetails from '@/modules/Admin/components/Creditors/ServiceFeeConditionDetails';
import { NAMESPACE } from '@/modules/Admin/store';
import { mapActions } from 'vuex';

export default {
  name: 'ServiceFeeMatrixRadioGroup',

  components: { ServiceFeeConditionDetails },

  mixins: [OnboardingProductMixin],

  props: {
    value: {
      type: [Number, String],
      default: null
    },
    name: {
      type: String,
      required: true
    },
    rules: {
      type: String,
      default: ''
    },
    creditorProductType: {
      type: String,
      required: true
    }
  },

  data: () => ({
    serviceFeeMatrixItems: [],
    loadingItems: true
  }),

  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },

  async created() {
    const { data } = await this.fetchServiceFeeMatrixList({
      filters: { product: [this.creditorProductType] }
    });

    this.loadingItems = false;

    if (!data) {
      return;
    }

    this.serviceFeeMatrixItems = data;
  },

  methods: {
    ...mapActions(NAMESPACE, ['fetchServiceFeeMatrixList'])
  }
};
</script>

<style scoped lang="scss">
.service-fee-matrix__radio-group {
  --gap: 16px;

  ::v-deep .v-input--radio-group__input {
    gap: var(--gap);
  }

  ::v-deep .v-input {
    padding: 0;
  }
}

.service-fee-matrix__radio {
  --padding: 16px;

  gap: 16px;
  padding: var(--padding);
  flex: 1 1 calc(50% - var(--gap));
  align-items: flex-start;
  border-radius: 2px;
  border: 1px solid currentColor;

  &.v-item--active {
    border-width: 2px;
    padding: calc(var(--padding) - 1px);

    &:not(.v-radio--is-disabled) {
      border-color: var(--c-primary);
    }
  }

  ::v-deep &.v-radio {
    margin: 0;
  }
}

.service-fee-matrix__name {
  display: inline-block;
  padding-top: 2px;
  padding-bottom: 8px;
  font-size: 1rem;
}

.service-fee-matrix__spinner {
  width: 100% !important;
}
</style>
