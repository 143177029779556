var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
return [_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"body-1 justify-center"},[_c('v-row',{staticClass:"stepper"},[_vm._l((_vm.steps),function(step){return _c('v-col',{directives:[{name:"ripple",rawName:"v-ripple",value:(_vm.nonLinear),expression:"nonLinear"}],key:step.step,class:{
            'd-flex align-center break-word': true,
            'stepper__step--selectable': _vm.nonLinear
          },on:{"click":function($event){_vm.nonLinear ? _vm.goToStep(step) : undefined}}},[(_vm.currentStep - 1 < step.step)?_c('span',{class:{
              'stepper__step-index': true,
              'gradient-diagonal--primary': _vm.currentStep === step.step
            }},[_vm._v(" "+_vm._s(step.step)+" ")]):_c('img',{staticClass:"stepper__step-index",attrs:{"src":require('@/onboarding/assets/check.svg'),"alt":step.step}}),_c('span',{staticClass:"ml-2 font-weight-light"},[_vm._v(_vm._s(_vm.$t(step.title)))])])}),_c('v-col',{staticClass:"d-flex",staticStyle:{"position":"relative"},attrs:{"cols":"12"}},[_c('v-progress-linear',{attrs:{"height":"5","value":_vm.currentStep * (100 / _vm.steps.length),"color":"secondary"}})],1)],2)],1),_c('v-card-text',[_c('error-alert',{attrs:{"value":failed}}),_vm._t("prepend-inner"),_c(_vm.steps[_vm.currentStepIndex].component,{tag:"component",staticClass:"mt-4",attrs:{"form-data":_vm.formData,"use-factoring":_vm.useFactoring,"disabled":_vm.disabled},on:{"update:form-data":function($event){return _vm.$emit('update:form-data', $event)}}}),(_vm.isOnboarding)?_c('div',{staticClass:"body-2 mt-4"},[_vm._v("*"+_vm._s(_vm.$t('onboarding.requiredFields')))]):_vm._e()],2),_c('v-card-actions',[_c('v-row',{attrs:{"justify":"center","align":"stretch"}},[_c('v-col',{attrs:{"cols":"6","md":"2"}},[_c('app-btn',{attrs:{"id":"creditor-registration__btn-cancel","block":"","outlined":"","title":_vm.currentStep > 1 ? 'navigation.goBack' : 'navigation.cancel',"disabled":_vm.loading},on:{"click":_vm.onCancel}})],1),_c('v-col',{attrs:{"cols":"6","md":"2"}},[(_vm.currentStep < _vm.steps.length)?_c('app-btn',{attrs:{"id":"creditor-registration__btn-submit","type":"button","block":"","title":"navigation.continue"},on:{"click":_vm.onNext}}):_c('app-btn',{attrs:{"id":"creditor-registration__btn-submit","type":"button","block":"","title":"navigation.send","disabled":_vm.disabled,"loading":_vm.loading},on:{"click":_vm.onSubmit}})],1)],1)],1)],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }