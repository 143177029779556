<template>
  <ul class="details__list">
    <li>
      {{ $t('serviceFees.purchasedFee') }}:
      {{ serviceFeeMatrix.purchasedFee | formatPercentage }}
    </li>
    <li>
      {{ $t('serviceFees.notPurchasedFee') }}:
      {{ serviceFeeMatrix.notPurchasedFee | formatPercentage }}
    </li>
    <li>
      {{ $t('serviceFees.purchasedLaterFee') }}:
      {{ serviceFeeMatrix.purchasedLaterFee | formatPercentage }}
    </li>
    <li>
      {{ $t('serviceFees.minimumServiceFee') }}:
      {{ serviceFeeMatrix.minimumServiceFee | formatCurrency }}
    </li>
    <li v-if="startDate">
      {{ dateLabel }}
    </li>
    <li v-if="creationDate">
      {{ $t('creditors.manageConditions.createdOn') }}:
      {{ creationDate | formatDate }}
    </li>
    <li>
      {{ $t('creditors.sapKey') }}:
      {{ serviceFeeMatrix.sapId }}
    </li>
  </ul>
</template>

<script>
import formatDate from '@/helper/filter/formatDate';

export default {
  name: 'ServiceFeeConditionDetails',

  props: {
    creationDate: {
      type: Number,
      default: null
    },
    endDate: {
      type: Number,
      default: null
    },
    isCurrent: {
      type: Boolean,
      default: false
    },
    serviceFeeMatrix: {
      type: Object,
      required: true
    },
    startDate: {
      type: Number,
      default: null
    }
  },

  computed: {
    dateLabel() {
      if (!this.startDate) {
        return '';
      }

      if (this.isCurrent) {
        return `${this.$t('creditors.manageConditions.validSince')}: ${formatDate(this.startDate)}`;
      }

      if (this.startDate > Date.now()) {
        return `${this.$t('creditors.manageConditions.validOn')}: ${formatDate(this.startDate)}`;
      }

      return `${this.$t('creditors.manageConditions.valid')}: ${formatDate(
        this.startDate
      )} - ${formatDate(this.endDate)}`;
    }
  }
};
</script>

<style scoped lang="scss">
.details__list {
  list-style-type: none;
  padding: 0;
  max-width: max-content;
  columns: 200px 2;
  column-gap: 48px;
  color: var(--c-grey);
  font-size: 0.875rem;

  li {
    break-inside: avoid;
    white-space: nowrap;
  }
}
</style>
