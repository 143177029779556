<template>
  <app-autocomplete
    v-model="selected"
    :items="items"
    :label="$t('creditors.legalForm')"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: 'LegalFormSelect',

  props: {
    value: {
      type: [Number, String],
      default: null
    },
    name: {
      type: [String],
      default: null
    }
  },

  data: () => ({
    showMenu: false,
    items: []
  }),

  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);

        if (!value) {
          return this.$emit('update:legalForm', '');
        }

        this.$emit('update:legalForm', this.items.find((item) => item.value === value).label);
      }
    }
  },

  async created() {
    const { data } = await this.$http.get('csp/provide-legal-form-mapping', {
      responseType: 'json'
    });

    this.items = Object.entries(data).map(([key, item]) => ({
      value: parseInt(key.replace('ID_', '')),
      label: item
    }));
  }
};
</script>
