<template>
  <div>
    <p>{{ $t('onboarding.sepaMandate') }}</p>
    <v-row dense class="py-4 mt-n2">
      <v-col v-if="dataNotBlacklisted(fields.ACCOUNT_OWNER)" cols="12">
        <app-textarea
          id="creditor-registration__account-owner"
          v-model="sepaData[fields.ACCOUNT_OWNER]"
          label="onboarding.accountOwner"
          rows="3"
          :rules="
            getRules(fields.ACCOUNT_OWNER, isOnboarding && !sepaData[fields.IBAN] ? '' : 'required')
          "
          :disabled="readOnly"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="dataNotBlacklisted(fields.IBAN)">
        <p>{{ $t('iban') }}</p>
        <v-row class="mt-n1">
          <v-col>
            <app-text-field
              id="creditor-registration__iban"
              v-model="sepaData[fields.IBAN]"
              name="iban"
              placeholder="DEXX XX XXXXXXXX"
              :rules="getRules(fields.IBAN, isOnboarding ? 'iban' : 'required|iban')"
              :disabled="readOnly"
            />
          </v-col>
        </v-row>
      </v-col>

      <v-col v-if="dataNotBlacklisted(fields.DATE) && !isOnboarding" cols="12" md="6">
        <p>{{ $t('date') }}</p>
        <v-row class="mt-n1">
          <v-col>
            <app-date-picker
              id="creditor-registration__date"
              v-model="sepaData[fields.DATE]"
              placeholder="dateHint"
              name="date"
              append-icon
              :rules="getRules(fields.DATE, 'required')"
              :disabled="readOnly"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import OnboardingProductMixin from '@/mixins/OnboardingProductMixin';

export default {
  name: 'SepaForm',

  mixins: [OnboardingProductMixin],

  props: {
    formData: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    sepaData: {}
  }),

  computed: {
    readOnly() {
      return this.sepaData.readOnly;
    }
  },

  watch: {
    formData: {
      handler() {
        if (JSON.stringify(this.formData[this.fields.SEPA]) === JSON.stringify(this.sepaData)) {
          return;
        }

        this.sepaData = { ...this.formData[this.fields.SEPA] };
      },
      immediate: true,
      deep: true
    },
    sepaData: {
      handler(value) {
        this.$emit('update:form-data', {
          ...this.formData,
          [this.fields.SEPA]: value
        });
      },
      deep: true
    }
  }
};
</script>
