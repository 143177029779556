<template>
  <app-select
    :disabled="items.length === 1"
    :items="items"
    label="creditors.setUpFee"
    required
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import formatCurrency from '@/helper/filter/formatCurrency';
import OnboardingProductMixin from '@/mixins/OnboardingProductMixin';

export default {
  name: 'SetupFeeSelect',

  mixins: [OnboardingProductMixin],

  computed: {
    items() {
      let values = this.selectedProduct.onboarding?.setUpFeeValues
        ? this.selectedProduct.onboarding.setUpFeeValues
        : [49900, 29900, 0];

      return values.map((value) => ({
        label: formatCurrency(value),
        value: value
      }));
    }
  },

  created() {
    if (this.$attrs.value === '') {
      this.$emit('input', this.items[0].value);
    }
  }
};
</script>

<style scoped>
>>> .v-select label {
  color: var(--c-grey);
  font-size: 16px;
}

>>> .v-select__selection {
  font-size: 16px;
}
</style>
